@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

/* Remove tap highlight on iOS */
* {
  -webkit-tap-highlight-color: transparent;
}

/* Remove tap feedback on Android */
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

body {
  background-color: #F9F9F9;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #EC671B;
}

.swiper-button-next,
.swiper-button-prev {
  background-image: url('../public/assets/icons/nav-arrow.svg');
  background-position: center;
  background-repeat: no-repeat;
  top: 36px;
  right: -14px;
  display: block;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 20;
  cursor: pointer;

  &.swiper-button-prev {
    left: 0px;
    transform: rotate(180deg);
  }


  &.swiper-button-disabled {
    display: none;
  }

  &:after {
    display: none;
  }
}

@media(max-width: 1024px) {

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}